import React, { Component } from "react"
import PageTitleArea from "../components/Common/PageTitleArea"
// import FreeTrialArea from '../components/Common/FreeTrialArea';
import Layout from "../components/layout"

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          <PageTitleArea pageTitle="About Us" pageDescription="Coming Soon" />
          {/*<FreeTrialArea />*/}
        </Layout>
      </React.Fragment>
    )
  }
}

export default About
